<template>
  <div>
    <v-img
      :src="header.img"
      height="400"
      class="grey darken-4"
      aspect-ratio="16/9"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
      <v-row justify="center" align="center" class="fill-height">
        <h1
          class="font-weight-bold text-center pa-6 text-h4 text-md-h3 white--text section-title"
        >
          {{ header.title }}
        </h1>
      </v-row>
    </v-img>
  </div>
</template>
<script>
export default {
  name: "PageHeader",
  props: ["header"],
  data: () => ({})
};
</script>
