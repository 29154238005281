<template>
  <v-container fluid>
    <v-data-iterator
      :items="news"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row class="amber lighten-1">
          <v-col cols="12" md="2" align-self="center" class="text-center">
            <h2 class="mt-5 mt-md-0">
              <span class="text-h4 font-weight-bold text--secondary"
                >お知らせ</span
              ><br />
              <span class="text-subtitle-1 text--secondary font-weight-bold">
                Information
              </span>
            </h2>
          </v-col>
          <v-col cols="12" md="10">
            <v-list three-line>
              <template v-for="(item, index) in props.items">
                <v-divider v-if="index >= 1" :key="index" inset></v-divider>

                <v-list-item :key="item.title" class="pl-0">
                  <v-icon class="mx-6">{{ item.icon }}</v-icon>

                  <v-list-item-content>
                    <v-list-item-subtitle
                      v-html="item.subtitle"
                    ></v-list-item-subtitle>
                    <p v-html="item.title"></p>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="pb-2 amber lighten-1" align="center" justify="center">
          <span class="mr-4"> ページ {{ page }} / {{ numberOfPages }} </span>
          <v-btn
            fab
            dark
            color="blue darken-3"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab dark color="blue darken-3" class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
export default {
  name: "Information",
  data: () => ({
    itemsPerPageArray: [3, 6, 9],
    page: 1,
    itemsPerPage: 3,
    news: [
      {
        icon: "mdi-youtube",
        title: `<a href="https://www.youtube.com/playlist?list=PL0mWnL1WiVUD9459wga1H3dPYcNIB-zk2" target="_blank">「14年目の福島 -現地の声を聴く!-」</a>`,
        subtitle: `<time>2024年3月9日</time> &mdash; 報告`
      },
      {
        icon: "mdi-youtube",
        title: `<a href="https://www.youtube.com/playlist?list=PL0mWnL1WiVUD9459wga1H3dPYcNIB-zk2" target="_blank">"ふるさとを返せ" 「ふるさと津島」他上映会&福島県浪江町・津島原発訴訟原告団講演会</a>`,
        subtitle: `<time>2023年3月11日</time> &mdash; 報告`
      },
      {
        icon: "mdi-youtube",
        title: `<a href="https://www.youtube.com/playlist?list=PL0mWnL1WiVUD9459wga1H3dPYcNIB-zk2" target="_blank">「13年目の福島 -現地の声を聴く!-」</a>`,
        subtitle: `<time>2023年1月7日</time> &mdash; 報告`
      },
      {
        icon: "mdi-youtube",
        title: `<a href="https://www.youtube.com/playlist?list=PL0mWnL1WiVUD9459wga1H3dPYcNIB-zk2" target="_blank">「サマショール ～遺言・第六章～」上映会&豊田直巳監督トーク(共催)</a>`,
        subtitle: `<time>2022年10月10日</time> &mdash; 報告`
      },
      {
        icon: "mdi-youtube",
        title: `<a href="https://www.youtube.com/watch?v=y4mr3cOUO3k&list=PL0mWnL1WiVUBR2E2BXfArj-fLz_EKz1QJ&index=1" title="YouTubeに移動します。" target="_blank">福島被災地研修ツアー</a>`,
        subtitle: `<time>2022年9月23-25日</time> &mdash; 報告`
      },
      {
        icon: "mdi-twitter",
        title: `映画上映会 (<a href="https://ubawaretamura.mystrikingly.com" target="_blank" rel="noopener">「奪われた村」</a>, <a href="https://furusato-tsushima.com">「ふるさと津島」</a>)を開催いたしました。</a>`,
        subtitle: `<time>2022年3月11日</time> &mdash; 告知`
      },
      {
        icon: "mdi-twitter",
        title: `<a href="https://twitter.com/Hoyou_Program/status/1457091006361325568" target="_blank">豊田直巳さんよりメッセージ「講演会を終えて」</a>`,
        subtitle: `<time>2021年11月6日</time> &mdash; 告知`
      },
      {
        icon: "mdi-youtube",
        title: `<a href="https://www.youtube.com/watch?v=SIM69360zr8&list=PL0mWnL1WiVUCOxMN78g0LR0hqVJGKWaOg" title="YouTubeに移動します。" target="_blank">豊田直巳講演会&写真展を開催いたしました。</a>`,
        subtitle: `<time>2021年11月6日</time> &mdash; 告知`
      },
      {
        icon: "mdi-comment-outline",
        title: `<a href="assets/pdf/2021/letter_of_thanks.pdf" target="_blank">南相馬市より当NPO法人の活動に対して感謝状を贈っていただきました。</a>`,
        subtitle: `<time>2021年11月3日</time> &mdash; 告知`
      },
      {
        icon: "mdi-comment-outline",
        title: `<a href="assets/pdf/2021/2021_11_06_event.pdf" target="_blank">11/6(土) 豊田直巳講演会&写真展開催のご案内。</a>`,
        subtitle: `<time>2021年10月1日</time> &mdash; 告知`
      },
      {
        icon: "mdi-comment-outline",
        title: `<a href="/activities/2012">2012年度活動</a>を追加しました。`,
        subtitle: `<time>2021年10月1日</time> &mdash; 更新`
      },
      {
        icon: "mdi-comment-outline",
        title: `<a href="/activities/2013">2013年度活動</a>を追加しました。`,
        subtitle: `<time>2021年9月1日</time> &mdash; 更新`
      },
      {
        icon: "mdi-comment-outline",
        title: `<a href="/activities/2014">2014年度活動</a>を追加しました。`,
        subtitle: `<time>2021年8月1日</time> &mdash; 更新`
      },
      {
        icon: "mdi-comment-outline",
        title: `<a href="/activities/2015">2015年度活動</a>を追加しました。`,
        subtitle: `<time>2021年7月1日</time> &mdash; 更新`
      },
      {
        icon: "mdi-comment-outline",
        title: `<a href="/activities/2016">2016年度活動</a>を追加しました。`,
        subtitle: `<time>2021年6月1日</time> &mdash; 更新`
      },
      {
        icon: "mdi-comment-outline",
        title: `<a href="/activities/2017">2017年度活動</a>を追加しました。`,
        subtitle: `<time>2021年5月1日</time> &mdash; 更新`
      },
      {
        icon: "mdi-comment-outline",
        title: `<a href="/activities/2018">2018年度活動</a>を追加しました。`,
        subtitle: `<time>2021年4月1日</time> &mdash; 更新`
      },
      {
        icon: "mdi-twitter",
        title: `<a href="https://twitter.com/Hoyou_Program/status/1377069063235510275" title="twitterに移動します。" target="_blank">小児科医・衆議院議員の阿部知子さんより福島の方々へのメッセージをいただきました。</a>`,
        subtitle: `<time>2021年3月28日</time> &mdash; 動画`
      },
      {
        icon: "mdi-youtube",
        title: `<a href="https://www.youtube.com/watch?v=T_HbOYgjldA" title="YouTubeに移動します。" target="_blank">福島県南相馬市の元市長・桜井勝延さんの講演会『東日本大震災から10年・復興の現状と課題』に参加しました。</a>`,
        subtitle: `<time>2021年3月28日</time> &mdash; 動画`
      },
      {
        icon: "mdi-comment-outline",
        title: "東日本大震災10年を期して、公式Webサイトを開設。",
        subtitle: `<time>2021年3月11日</time> &mdash; 告知`
      }
      // {
      //   icon: "mdi-comment-outline mdi-comment-alert-outline",
      //   title: `私達と一緒に活動してくださる<a href="/support">ボランティア募集中</a>です。`,
      //   subtitle: `<time>2021年2月1日</time> &mdash; 告知 <span class="red--text">重要</span>`
      // }
    ]
  }),
  computed: {
    numberOfPages() {
      return Math.ceil(this.news.length / this.itemsPerPage);
    }
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    }
  }
};
</script>
