<template>
  <v-container>
    <v-row v-if="post.time">
      <v-col cols="6" sm="4" md="2" tag="dl">
        <dt>回</dt>
        <dd class="text-h3 text-center">{{ post.time }}</dd>
      </v-col>
      <v-col cols="6" sm="4" md="2" tag="dl">
        <dt>参加者</dt>
        <dd class="text-h3 text-center">{{ post.partipants }}</dd>
      </v-col>
      <v-col cols="12" sm="4" md="3" tag="dl" class="text-center">
        <dt>時期</dt>
        <v-chip class="ma-2" :color="post.color" text-color="white">
          {{ post.season }}
        </v-chip>
        <dd class="text-center">{{ post.date }}</dd>
      </v-col>
      <v-col cols="12" md="5" tag="dl" class="text-center">
        <dt>宿泊場所</dt>
        <dd>{{ post.place }}</dd></v-col
      >
      <v-col cols="12" tag="dl" class="text-center">
        <dt>内容</dt>
        <dd>{{ post.content }}</dd>
      </v-col>
    </v-row>
    <template v-if="post.text">
      <p class="pa-6 mb-0">
        <span>{{ post.textData }}:</span>
        <v-icon class="mx-3" v-if="post.textIcon">{{ post.textIcon }}</v-icon>
        <span v-html="post.text"></span>
      </p>
    </template>
  </v-container>
</template>

<script>
export default {
  name: "ActionData",
  props: ["post"],

  data: () => ({
    //
  })
};
</script>

<style lang="scss" scoped>
::v-deep dt {
  font-size: 0.7rem;
  text-align: center;
}
</style>
