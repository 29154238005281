import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Activities from "../views/Activities.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "HOME",
      desc:
        "福島の被災地の子ども達を湘南に招待し、様々な体験を通して交流を深めてまいりました。2012年より活動しております。"
    }
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "私たちについて",
      desc: "交流を通じた子ども達への支援をしております。"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/activities/",
    name: "Activities",
    component: Activities,
    meta: {
      title: "活動紹介",
      desc:
        "2012年度より、年2回子ども達を招待し、様々な体験を通じて交流を深めてまいりました。"
    }
  },
  {
    path: "/activities/:id",
    name: "EachAction",
    meta: {
      title: "年度の活動",
      desc: "1年間通しての活動を年度ごとにご紹介。"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EachAction.vue")
  },
  {
    path: "/history",
    name: "History",
    meta: {
      title: "福島の歴史",
      desc: "福島の歴史から見えてくるこれからの話。"
    },
    component: () =>
      import(/* webpackChunkName: "history" */ "../views/History.vue")
  },
  {
    path: "/support",
    name: "Support",
    meta: {
      title: "皆様のご支援が必要です",
      desc: "皆様のお力により子ども達の未来を形にできます。"
    },
    component: () =>
      import(/* webpackChunkName: "support" */ "../views/Support.vue")
  },
  {
    path: "/links",
    name: "Links",
    meta: {
      title: "リンク集",
      desc: "関連団体などのリンク集です。"
    },
    component: () =>
      import(/* webpackChunkName: "history" */ "../views/Links.vue")
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          selector: to.hash
        };
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    }
  },
  routes
});

export default router;
