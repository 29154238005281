<template>
  <div class="home">
    <v-img
      src="assets/pic/home/20211111_mini.jpg"
      max-height="614"
      class="grey darken-4"
      contain
      aspect-ratio="2.1"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <Information />
    <v-row no-gutters>
      <v-col cols="12" sm="7" order="1" order-sm="0">
        <v-container
          class="grey lighten-5 fill-height flex-column justify-center"
          fluid
        >
          <h2
            class="
              text-h4 text-md-h3
              font-weight-bold
              text--secondary
              home-title
              my-16
            "
          >
            わたし達にできること
          </h2>
          <p class="mb-16">
            東日本大震災で多くを失った被災地の子ども達が、未来に希望を持って生きる。<br />
            そんな社会をめざして、 「こども達に未来を in
            湘南」は2012年から活動しています。
          </p>
          <v-btn to="/about" x-large color="amber" class="mb-16">
            <v-icon left>mdi-chevron-right</v-icon>
            <span class="mr-2 font-weight-bold">私たちについて</span>
          </v-btn>
        </v-container>
      </v-col>

      <v-col cols="12" sm="5" order="0" order-sm="1">
        <v-img
          src="/assets/pic/home/enoshima.jpg"
          class="grey lighten-2 fill-height"
          min-height="300px"
          aspect-ratio="16/9"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

    <v-container class="text-center green grey--text text--lighten-3" fluid>
      <h2 class="text-h4 text-md-h3 font-weight-bold home-title my-16">
        わたし達の取り組み
      </h2>
      <p class="mb-16">
        2012年より、年2回ペースで福島のこども達を湘南に招待。<br />
        様々な体験を通し、交流を深めてまいりました。
      </p>
      <v-btn to="/activities" x-large color="amber" class="mb-16">
        <v-icon left>mdi-chevron-right</v-icon>
        <span class="mr-2 font-weight-bold">これまでの活動</span>
      </v-btn>
    </v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="5">
        <v-img
          src="/assets/pic/home/4246962_m_mini.jpg"
          class="grey lighten-2 fill-height"
          min-height="300px"
          aspect-ratio="16/9"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col cols="12" sm="7">
        <v-container
          class="grey lighten-5 fill-height flex-column justify-center"
          fluid
        >
          <h2
            class="
              text-h4 text-md-h3
              font-weight-bold
              text--secondary
              home-title
              my-16
            "
          >
            皆さんにできること
          </h2>
          <p class="mb-16">
            東日本大震災で多くを失った被災地の子ども達が、未来に希望を持って生きる。<br />
            そんな社会をめざして、 「こども達に未来を in
            湘南」は2012年から活動しています。
          </p>
          <v-btn to="/support" x-large color="amber" class="mb-16">
            <v-icon left>mdi-chevron-right</v-icon>
            <span class="mr-2 font-weight-bold">ボランティア募集・寄付</span>
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Information from "@/components/Information.vue";
// import EventInfo from "@/components/EventInfo.vue";
export default {
  name: "Home",
  components: { Information },
  data: () => ({})
};
</script>

<style lang="scss">
.home-title {
  padding-bottom: 1rem;
  display: inline-block;
  &::after {
    content: "";
    display: block;
    margin: 20px auto 0;
    width: 78px;
    height: 8px;
    border-radius: 5px;
    background: #ffca28;
  }
}
</style>
