<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle> NPO法人</v-list-item-subtitle>
          <v-list-item-title class="font-weight-bold">
            こども達に未来を in 湘南
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item-group color="light-blue darken-4">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="https://forms.gle/wVUCxBaaXXPoJToy5">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>お問い合わせ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="light-blue darken-4" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="font-weight-bold body-2 text-md-subtitle-1">
        こども達に未来を in 湘南
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="/support" color="amber" light>
        <span class="mr-2 font-weight-bold">支援する</span>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Footer
  },
  data: () => ({
    drawer: null,
    items: [
      { title: "Home", icon: "mdi-home", to: "/" },
      { title: "私たちについて", icon: "mdi-flower", to: "/about" },
      { title: "これまでの活動", icon: "mdi-image", to: "/activities" },
      { title: "福島の歴史", icon: "mdi-text-box", to: "/history" },
      { title: "支援する", icon: "mdi-heart", to: "/support" },
      { title: "リンク集", icon: "mdi-link-variant", to: "/links" }
    ],
    right: null
  }),
  methods: {
    createTitleDesc: function (routeInstance) {
      const dafaultTitle =
        routeInstance.meta.title + " | NPO法人 こども達に未来を in 湘南";
      if (this.$route.params.id) {
        const setTitle = this.$route.params.id + dafaultTitle;
        document.title = setTitle;
      } else if (routeInstance.meta.title) {
        document.title = dafaultTitle;
      } else {
        document.title = "NPO法人 こども達に未来を in 湘南";
      }

      if (routeInstance.meta.desc) {
        const setDesc = routeInstance.meta.desc;
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", setDesc);
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", "descriotion is not set");
      }
    }
  },
  mounted: function () {
    const routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
  },
  beforeCreate: function () {
    const lazyCss = document.querySelectorAll(".lazy-css");
    for (let i = 0; i < lazyCss.length; i = (i + 1) | 0) {
      lazyCss[i].rel = "stylesheet";
    }
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    }
  }
};
</script>
