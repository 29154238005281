<template>
  <div class="activity">
    <PageHeader :header="headData" />
    <v-container fluid class="grey lighten-5">
      <v-timeline :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item
          v-for="(item, i) in post"
          :key="i"
          color="yellow accent-4"
        >
          <v-card class="elevation-1 text--secondary">
            <v-card-title class="text-h4"> {{ item.year }}年度 </v-card-title>
            <div v-for="(act, t) in item.times" :key="t">
              <ActionData :post="act" />
              <v-divider class="mx-4"></v-divider>
            </div>
            <v-btn
              :to="item.path"
              :color="item.color"
              class="ma-3"
              outlined
              x-large
              v-if="item.path"
            >
              詳細
            </v-btn>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </div>
</template>

<script>
import ActionData from "@/components/ActionData.vue";
import PageHeader from "@/components/PageHeader.vue";
export default {
  name: "activities",
  components: { ActionData, PageHeader },
  data: () => ({
    headData: {
      title: "これまでの活動",
      img: "/assets/pic/header/bg_activity_mini.jpg"
    },
    post: [
      {
        year: "2022",
        path: "",
        color: "amber darken-2",
        times: [
          {
            textData: "2023年3月11日",
            textIcon: "mdi-youtube",
            text: `<a
                href="https://www.youtube.com/playlist?list=PL0mWnL1WiVUApq1kxYVLekuZHlBan5Mfa"
                title="youtubeに移動します。"
                >ふるさとを返せ" 「ふるさと津島」他上映会&福島県浪江町・津島原発訴訟原告団講演会</a>`
          },
          {
            textData: "2023年1月7日",
            textIcon: "mdi-youtube",
            text: `<a
                href="https://www.youtube.com/playlist?list=PL0mWnL1WiVUB32YN5uZCrfIL3yqFUNW2D"
                title="youtubeに移動します。"
                >「13年目の福島 -現地の声を聴く!-」</a>`
          },
          {
            textData: "2022年10月10日",
            textIcon: "mdi-youtube",
            text: `<a
                href="https://www.youtube.com/playlist?list=PL0mWnL1WiVUDRE8HsgrxNA93mGOd6tN6q"
                title="youtubeに移動します。"
                >「サマショール -遺言・第六章-」(監督:豊田直巳)上映会、講演会(共催)</a>`
          },
          {
            textData: "2022年9月23日-25日",
            textIcon: "mdi-youtube",
            text: `<a
                href="https://www.youtube.com/watch?v=y4mr3cOUO3k&list=PL0mWnL1WiVUBR2E2BXfArj-fLz_EKz1QJ&index=1"
                title="youtubeに移動します。"
                >福島被災地研修ツアー</a>
                <br>
            <a
                href="assets/pdf/2022/2022_fukushima_kenshuu.pdf"
                title="福島被災地研修ツアー・写真４枚.pdf"
                >・写真４枚</a>`
          }
        ]
      },
      {
        year: "2021",
        path: "",
        color: "amber darken-2",
        times: [
          {
            textData: "2022年3月11日",
            textIcon: "mdi-message-alert-outline",
            text: `映画上映会を開催。<br>
              <a
                href="https://ubawaretamura.mystrikingly.com"
                title="別サイトに移動します。"
                >・「奪われた村」</a>
                <br>
                <a
                href="https://furusato-tsushima.com"
                title="別サイトに移動します。"
                >・「ふるさと津島」</a>
                <br>
                <a
                href="assets/pdf/2021/2022_03_11_pics.pdf"
                title="イベントの写真"
                >・写真.pdf</a>`
          },
          {
            textData: "2021年11月6日",
            textIcon: "mdi-message-alert-outline",
            text: `豊田直巳講演会・写真展 ～フクシマ 尊厳の記録と記憶～」開催。<br>
              <a
                href="https://www.youtube.com/watch?v=SIM69360zr8&list=PL0mWnL1WiVUCOxMN78g0LR0hqVJGKWaOg"
                title="youtubeに移動します。"
                >・講演会の動画</a>
                <br>
                <a
                href="assets/pdf/2021/2021_11_06_event.pdf"
                title="イベントのチラシ"
                >・チラシ.pdf</a>
                <br>
                <a
                href="assets/pdf/2021/2021_11_06_pics.pdf"
                title="イベントの写真"
                >・写真.pdf</a>
                <br>
                <a
                href="assets/pdf/2021/2021_11_questionnaire.pdf"
                title="イベントのアンケート"
                >・アンケート.pdf</a>`
          },
          {
            textData: "2021年11月3日",
            textIcon: "mdi-text-box-outline",
            text: `<a
                href="assets/pdf/2021/letter_of_thanks.pdf"
                title="感謝状２枚.pdf"
                >南相馬市より当NPO法人の活動に対して感謝状を贈っていただきました。</a>`
          }
        ]
      },
      {
        year: "2020",
        path: "",
        color: "amber darken-2",
        times: [
          {
            textData: "2021年3月28日",
            textIcon: "mdi-youtube",
            text: `<a
                href="https://www.youtube.com/watch?v=T_HbOYgjldA"
                title="YouTubeに移動します。"
                >福島県南相馬市の元市長・桜井勝延さんの講演会『東日本大震災から10年・復興の現状と課題』に参加。</a
              >`
          },
          {
            textData: "2021年3月11日",
            textIcon: "mdi-message-alert-outline",
            text: `NPO法人公式webサイトを開設。`
          }
        ]
      },
      {
        year: "2019",
        path: "/activities/2019",
        color: "amber darken-2",
        times: [
          {
            time: "19",
            partipants: "-",
            season: "-",
            date: "-月-日",
            content: "春の保養活動を立案していたが、コロナ禍により急遽中止",
            place: "-",
            color: "pink lighten-3"
          },
          {
            time: "18",
            partipants: "31",
            season: "夏",
            date: "2019年7月26日-29日",
            content: "フラダンス、ギター",
            place: "慶応義塾大学湘南藤沢キャンパス(SFC)",
            color: "green"
          }
        ]
      },
      {
        year: "2018",
        path: "/activities/2018",
        color: "amber darken-2",
        times: [
          {
            time: "17",
            partipants: "26",
            season: "春",
            date: " 2019年3月28日-31日",
            content: "ビンゴ大会、交流会、マジックショーなど。",
            place: "慶応義塾大学湘南藤沢キャンパス(SFC)",
            color: "pink lighten-3"
          },
          {
            time: "16",
            partipants: "32",
            season: "夏",
            date: "2018年7月26日-29日",
            content: "プール、映画、カレー作り、マジックショー、交流会など",
            place: "慶応義塾大学湘南藤沢キャンパス(SFC)",
            color: "green"
          }
        ]
      },
      {
        year: "2017",
        path: "/activities/2017",
        color: "amber darken-2",
        times: [
          {
            time: "15",
            partipants: "32",
            season: "春",
            date: " 2018年3月25日-28日",
            content: "ふれあい動物園、乗馬体験、バーベキューなど",
            place: "慶応義塾大学湘南藤沢キャンパス(SFC)",
            color: "pink lighten-3"
          },
          {
            time: "14",
            partipants: "30",
            season: "夏",
            date: "2017年7月28日-31日",
            content: "日大見学、バーベキュー、マジック、セイリングなど",
            place: "日本大学生物資源科学部農場研修センター",
            color: "green"
          }
        ]
      },
      {
        year: "2016",
        path: "/activities/2016",
        color: "amber darken-2",
        times: [
          {
            time: "13",
            partipants: "31",
            season: "春",
            date: "2017年3月25日-28日",
            content: "ジャグリング、ギター、クイズ、ボーリングなど",
            place: "日本大学生物資源科学部農場研修センター",
            color: "pink lighten-3"
          },
          {
            time: "12",
            partipants: "37",
            season: "夏",
            date: "2016年7月29日-8月1日",
            content: "ウクレレ、地引網、バーベキュー、マジックなど",
            place: "日本大学生物資源科学部農場研修センター",
            color: "green"
          }
        ]
      },
      {
        year: "2015",
        path: "/activities/2015",
        color: "amber darken-2",
        times: [
          {
            time: "11",
            partipants: "24",
            season: "春",
            date: " 2016年3月26日-29日",
            content: "鎌倉よさこい、バイオリン、バーベキュー、セイリングなど",
            place: "日本大学生物資源科学部農場研修センター",
            color: "pink lighten-3"
          },
          {
            time: "10",
            partipants: "20",
            season: "夏",
            date: "2015年7月26日-29日",
            content: "バイオリン、鎌倉よさこい、セイリング、新江ノ島水族館など",
            place: "日本大学生物資源科学部農場研修センター",
            color: "green"
          },
          {
            textData: "2015年6月16日",
            textIcon: "mdi-message-alert-outline",
            text: `第10回 「こども達未来・湘南保養プログラム」説明会を開催。`
          }
        ]
      },
      {
        year: "2014",
        path: "/activities/2014",
        color: "amber darken-2",
        times: [
          {
            time: "9",
            partipants: "20",
            season: "春",
            date: " 2015年3月27日-30日",
            content: "ギター、江ノ島、ディズニーストアなど",
            place: "日本大学生物資源科学部農場研修センター",
            color: "pink lighten-3"
          },
          {
            textData: "2015年3月15日",
            textIcon: "mdi-message-alert-outline",
            text: `映画「日本と原発」上映会`
          },
          {
            textData: "2015年3月10日-11日",
            textIcon: "mdi-message-alert-outline",
            text: `南相馬市原発事故4年研修旅行`
          },
          {
            textData: "2015年1月24日",
            textIcon: "mdi-message-alert-outline",
            text: `講演会「逃げ遅れる人々」`
          },
          {
            time: "8",
            partipants: "40",
            season: "夏B",
            date: "2014年8月25日-28日",
            content: "フルート、オカリナ、琵琶、お囃子、建長寺など",
            place: "鎌倉・建長寺",
            color: "green"
          },
          {
            time: "7",
            partipants: "28",
            season: "夏A",
            date: " 2014年7月28日-31日",
            content: "地引網、スイカ割り、江ノ島など",
            place: "日本大学生物資源科学部農場研修センター",
            color: "green"
          }
        ]
      },
      {
        year: "2013",
        path: "/activities/2013",
        color: "amber darken-2",
        times: [
          {
            time: "6",
            partipants: "39",
            season: "春",
            date: "2014年3月26日-29日",
            content: "寒川神社参拝、新江ノ島水族館、江ノ島観光など",
            place: "寒川神社",
            color: "pink lighten-3"
          },
          {
            time: "5",
            partipants: "54",
            season: "夏B",
            date: " 2013年8月2日-5日",
            content: "地引網、スイカ割り、江ノ島観光など",
            place: "鎌倉・建長寺",
            color: "green"
          },
          {
            time: "4",
            partipants: "25",
            season: "夏A",
            date: "2013年7月26日-29日",
            content:
              "アンパンマンミュージアム、日清カップヌードルミュージアムなど",
            place: "寒川神社",
            color: "green"
          },
          {
            textData: "2013年4月2日",
            textIcon: "mdi-text-box-outline",
            text: `<a
                href="/assets/pdf/2013/2013_4_2_kanagawa_newspaper.pdf"
                title="pdfで開きます。"
                >神奈川新聞に掲載。</a
              >`
          }
        ]
      },
      {
        year: "2012",
        path: "/activities/2012",
        color: "amber darken-2",
        times: [
          {
            time: "3",
            partipants: "21",
            season: "春",
            date: "2013年3月5日-31日",
            content:
              "新江ノ島水族館、日清カップヌードルミュージアム、横浜中華街、鎌倉観光など",
            place: "中井町児童館、山口宅",
            color: "pink lighten-3"
          },
          {
            time: "2",
            partipants: "15",
            season: "冬",
            date: "2012年12月24日",
            content:
              "遊行寺懇談会、クリスマス・ミサ、餅つき大会、新江ノ島水族館、鎌倉観光など",
            place: "藤沢・遊行寺",
            color: "indigo lighten-1"
          },
          {
            time: "1",
            partipants: "83",
            season: "夏",
            date: "2012年8月21日",
            content: "地引網、BBQ、辻堂海浜公園プールなど",
            place: "藤沢市立白浜養護学校、高砂小学校",
            color: "green"
          },
          {
            textData: "2012年6月26日,8月22日",
            textIcon: "mdi-text-box-outline",
            text: `<a
                href="/assets/pdf/2012/2012_newspapers.pdf"
                title="pdfで開きます。"
                >毎日新聞湘南版、朝日新聞に掲載。</a
              >`
          }
        ]
      }
    ]
  })
};
</script>
