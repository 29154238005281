<template>
  <v-footer dark padless class="light-blue darken-4">
    <v-row class="svg-box mx-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        class="footer-svg"
      >
        <path
          d="M0,0 v50 q10,15 20,0 t20,0 t20,0 t20,0 t20,0 v-50 Z"
          fill="#fafafa"
        ></path>
      </svg>
    </v-row>
    <v-container class="footer-container" fluid>
      <v-row>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-2"
          dark
          icon
          :href="icons[i].url"
        >
          <v-icon size="24px">
            {{ icons[i].icon }}
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="scrollTop"
          elevation="2"
          color="amber"
          light
          class="mr-2 mr-sm-5 caption text-sm-button"
          ><v-icon>mdi-chevron-up</v-icon>先頭へ</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="7">
          <v-img
            height="92"
            width="254"
            src="/assets/pic/logo2.svg"
            alt="npo法人 こども達に未来を in 湘南"
            contain
            class="mt-4 mt-sm-0"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card class="transparent body-2 mt-6" flat>
            <address>
              <p class="mt-2 mb-1">〒251-0032 神奈川県藤沢市片瀬4-1-16</p>
              <p class="mb-1">
                <a class="tel" href="tel:0466552251">電話: 0466-55-2251</a> /
                Fax: 0466-22-8721
              </p>
              <p class="mb-1">Mail: kodomotachimirai@yahoo.co.jp</p>
            </address>
          </v-card>
        </v-col>
        <v-col cols="12" sm="5">
          <Timeline
            id="Hoyou_Program"
            sourceType="profile"
            :options="{ lang: 'ja', height: 300 }"
            class="mr-2"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-text class="py-2 white--text text-center light-blue darken-3">
      2012年 &copy; <strong>NPO法人 こども達に未来を in 湘南</strong>
    </v-card-text>
  </v-footer>
</template>

<script>
import { Timeline } from "vue-tweet-embed";
export default {
  name: "Footer",
  components: {
    Timeline
  },
  data: () => ({
    icons: [
      {
        icon: "mdi-facebook",
        url: "https://www.facebook.com/pages/category/Nonprofit-Organization/NPO%E6%B3%95%E4%BA%BA%E3%81%93%E3%81%A9%E3%82%82%E9%81%94%E3%81%AB%E6%9C%AA%E6%9D%A5%E3%82%92in%E6%B9%98%E5%8D%97-1787759061515646/"
      },
      { icon: "mdi-twitter", url: "https://twitter.com/Hoyou_Program" },
      { icon: "mdi-email", url: "https://forms.gle/wVUCxBaaXXPoJToy5" }
    ]
  }),
  methods: {
    scrollTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.test2 {
  li {
    list-style-type: none;
  }
}
.footer-container {
  max-width: 960px;
}
</style>
